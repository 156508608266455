/* eslint-disable array-callback-return */
/* eslint-disable func-names */
const traverse = require('traverse');
const _get = require('lodash/get');
const country = require('./country');

/**
 * In order to deliver on launching the same project template for our swedish
 * website, some shortcuts had to be made. One of this, is the following override
 * for set locale.
 *
 * What this essentially does, is if the country is set to swedish, and the
 *  translation object has a 'sv' key, it will prefer that. Otherwise keep to
 *  normal traversal.
 *
 *  Example:
 *
 *	 country === 'sweden':
 *
 *  meatball: {
 *	  no: 'Kjøttboller',
 *	  sv: 'Köttbullar', <- prefer this.
 *	  en: 'Meat balls' <- Otherwise this, if locale === 'en'
 *  }
 *
 *  salmon: {
 *	  no: 'Laks', <- Will default to this if locale === no
 *	  en: 'Salmon' <- Or this if on english
 *  }
 */

const localeOverride = {
  sweden: {
    no: 'sv',
    en: 'en',
  },
  norway: {
    no: 'no',
    en: 'en',
  },
};

/**
  This traverses the node (from graphQL) and returns a new
  object stripped of irrelevant locales.

  e.g.
  given data = title: { no, en }
  getLocalizedData({ data, locale: 'en' }) would return
  --> title.en

  This makes for cleaner template files as we select data
  only once instead of doing so on every localized item
*/
const getLocalizedData = (data, locale) => {
  const traversed = traverse(data).map(function (field) {
    if (field && typeof field === 'object') {
      const keys = Object.keys(this.node);
      const isLocalized =
        (keys.includes('en') && keys.includes('no')) || keys.includes(locale);

      const primaryLocalOverride = _get(
        localeOverride,
        `${country?.default}.${locale}`,
        localeOverride.norway[locale],
      );

      if (isLocalized) {
        if (this.node[primaryLocalOverride]) {
          this.update(this.node[primaryLocalOverride]);
        } else {
          this.update(this.node[locale]);
        }
      } else if (keys.includes('en') && !keys.includes('no')) {
        this.update(this.node.en);
      } else if (keys.includes('no') && !keys.includes('en')) {
        this.update(this.node.no);
      }
    }
  });

  return traversed;
};

module.exports = getLocalizedData;
