exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-bestill-time-jsx": () => import("./../../../src/pages/bestill-time.jsx" /* webpackChunkName: "component---src-pages-bestill-time-jsx" */),
  "component---src-pages-en-404-jsx": () => import("./../../../src/pages/en/404.jsx" /* webpackChunkName: "component---src-pages-en-404-jsx" */),
  "component---src-pages-en-book-an-appointment-jsx": () => import("./../../../src/pages/en/book-an-appointment.jsx" /* webpackChunkName: "component---src-pages-en-book-an-appointment-jsx" */),
  "component---src-templates-clinic-page-jsx": () => import("./../../../src/templates/clinicPage.jsx" /* webpackChunkName: "component---src-templates-clinic-page-jsx" */),
  "component---src-templates-faq-sub-topic-jsx": () => import("./../../../src/templates/faqSubTopic.jsx" /* webpackChunkName: "component---src-templates-faq-sub-topic-jsx" */),
  "component---src-templates-faq-topic-jsx": () => import("./../../../src/templates/faqTopic.jsx" /* webpackChunkName: "component---src-templates-faq-topic-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */)
}

