module.exports = {
  search: {
    en: 'Search',
    no: 'Søk',
  },
  menu: {
    no: 'Meny',
    en: 'Menu',
  },
  closeMenu: {
    no: 'Lukk meny',
    en: 'Close menu',
  },
  back: {
    no: 'Tilbake',
    en: 'Back',
  },
  close: {
    no: 'Lukk',
    en: 'Close',
  },
  cancel: {
    no: 'Lukk',
    en: 'Cancel',
  },
  edit: {
    no: 'Endre',
    en: 'Edit',
  },
  help: {
    no: 'Hjelp',
    en: 'Help',
  },
  contactButton: {
    no: 'Kontakt',
    en: 'Contact',
  },
  next: {
    no: 'Neste',
    en: 'Next',
  },
  closed: {
    no: 'Stengt i dag',
    en: 'Closed today',
  },
  fullyBooked: {
    no: 'Fullbooket i dag',
    en: 'Fully booked today',
  },
  expandHide: {
    no: 'Skjul',
    en: 'Hide',
  },
  expandShow: {
    no: 'Vis mer',
    en: 'Show more',
  },
  morning: {
    no: 'Morgen',
    en: 'Morning',
  },
  afternoon: {
    no: 'Ettermiddag',
    en: 'Afternoon',
  },
  evening: {
    no: 'Kveld',
    en: 'Evening',
  },
  date: {
    no: 'Dato',
    en: 'Date',
  },
  viewMap: {
    no: 'Se kart',
    en: 'View map',
  },
  backToHomepage: {
    no: 'Tilbake til nettsiden',
    en: 'Back to homepage',
  },
  nearestClinic: {
    no: 'Nærmeste klinikk',
    en: 'Nearest clinic',
  },
  otherClinicsIn: {
    no: 'Andre klinikker i',
    en: 'Other clinics in',
  },
  ourClinics: {
    no: 'Våre klinikker',
    en: 'Our clinics',
  },
  sortBy: {
    no: 'Sorter etter',
    en: 'Sort by',
  },
  allOptions: {
    no: 'Alle',
    en: 'All',
  },
  testClinic: {
    no: 'Testklinikk',
    en: 'Test clinic',
  },
  medicalClinic: {
    no: 'Legeklinikk',
    en: 'Medical clinic',
  },
  bookAnAppointment: {
    no: 'Se ledige timer',
    en: 'See availability',
    sv: 'Se tillgängliga tider',
  },
  bookAVideoAppointment: {
    no: 'Bestill videosamtale',
    en: 'Book a video appointment',
  },
  findNearestClinic: {
    no: 'Finn nærmeste klinikk',
    en: 'Find nearest clinic',
  },
  localeSwitcher: {
    no: 'Bytt språk',
    en: 'Switch language',
  },
  reversedLocaleSwitcher: {
    no: 'Switch language',
    en: 'Bytt språk',
  },
  noGeolocation: {
    no: 'Lokasjon ikke tilgjengelig',
    en: 'Geolocation is unavailable',
  },
  clinics: {
    no: 'Klinikker',
    en: 'Clinics',
  },
  backTo: {
    no: 'Tilbake til',
    en: 'Back to',
  },
  company: {
    orgNr: {
      no: 'Organisasjonsnummer',
      sv: 'Organisationsnummer',
      en: 'Org. number',
    },
    address: {
      no: 'Adresse',
      sv: 'Adress',
      en: 'Address',
    },
    postbox: {
      no: 'Postadresse',
      en: 'Postal address ',
    },
    phone: {
      no: 'Mobilnummer',
      sv: 'Tlf nummer',
      en: 'Mobile number',
    },
    email: {
      no: 'E-post',
      sv: 'E-post',
      en: 'Email',
    },
    headline1: {
      no: 'Alle helsetjenester',
      sv: 'Alla sjukvårdstjänster',
      en: 'All health services',
    },
    headline2: {
      no: 'Time på dagen',
      sv: 'Timme på dygnet',
      en: 'Appointment today',
    },
    headline3: {
      no: 'Fast pris',
      sv: 'Fast pris',
      en: 'Fixed price',
    },
  },
  steps: [
    {
      no: 'Velg time',
      en: 'Choose type',
    },
    {
      no: 'Velg tjeneste',
      en: 'Choose service',
    },
    {
      no: 'Velg behandling',
      en: 'Choose treatment',
    },
    {
      no: 'Velg område',
      en: 'Choose area',
    },
    {
      no: 'Velg klinikk i',
      en: 'Choose clinic in',
    },
    {
      no: 'Velg tid',
      en: 'Choose time',
    },
    {
      no: 'Din informasjon',
      en: 'Your information',
    },
    {
      no: 'Bekreft bestilling',
      en: 'Confirm order',
    },
    {
      no: 'Timen er bestilt',
      en: 'Appointment booked',
    },
  ],
  clinic: {
    no: 'klinikk',
    en: 'clinic',
  },
  clinicPath: {
    no: 'klinikker',
    en: 'en/clinics',
  },
  psychologists: {
    no: 'Psykologer',
    en: 'Psychologists',
  },
  specialists: {
    no: 'Spesialister',
    en: 'Specialists',
  },
  regularDoctor: {
    no: 'Allmennleger',
    en: 'General practitioners',
  },
  physicalService: {
    no: 'Velg behandling',
    en: 'Select service',
  },
  physicalServiceType: {
    no: 'Velg type time',
    en: 'Select appointment type',
  },
  videoService: {
    no: 'Videosamtale',
    en: 'Video consultation',
  },
  covidTest: {
    no: 'Koronatest og Fit-to-Fly',
    en: 'Covid test and Fit-to-Fly',
  },
  proofOfRecovery: {
    no: 'Proof of recovery',
    en: 'Proof of recovery',
  },
  medicalAdvice: {
    no: 'Medisinsk rådgivning på video',
    en: 'Medical advice on video',
  },
  standardPcr: {
    no: 'Standard RT-PCR',
    en: 'Standard RT-PCR',
  },
  standardPcrDesc: {
    no: 'Svar innen 1-3 arbeidsdager',
    en: 'Results in 1-3 working days',
  },
  standardPcrPriceInfo: {
    no: 'Fit-to-Fly inkludert',
    en: 'Fit-to-Fly included',
  },
  expressPcr: {
    no: 'Express RT-PCR',
    en: 'Express RT-PCR',
  },
  expressPcrDesc: {
    no: 'Svar innen 2 timer',
    en: 'Restults in 2 hours',
  },
  expressPcrPriceInfo: {
    no: 'Fit-to-Fly inkludert',
    en: 'Fit-to-Fly included',
  },
  antigenTest: {
    en: 'Antigen test',
    no: 'Antigen test',
  },
  antigenTestDesc: {
    no: 'Svar innen  2 timer',
    en: 'Results in 2 hours',
  },
  antigenTestPriceInfo: {
    no: 'Fit-to-Fly inkludert',
    en: 'Fit-to-Fly included',
  },

  clinicService: {
    no: 'Klinikk',
    en: 'Clinic',
  },
  booking: {
    psyModal: {
      title: {
        no: 'Psykolog når du trenger det.',
        en: 'Psychologist video consultation',
      },
      subtitle: {
        no: 'Få hjelpen og støtten du trenger gjennom samtale med en av våre psykologer',
        en: 'Psychologist video consultation where you are',
      },
      description: {
        no: 'Hos oss kan du komme til en enkel samtale, eller følges opp så lenge du kjenner behov for det. Time i klinikk, på tur eller over video? Vi tar samtalen slik du foretrekker den. Last ned appen vår for å bestille time.',
        en: 'You do not need to go to the clinic to get the help you need. Book an appointment and talk to a psychologist wherever you are - in bed, the office or on vacation.',
      },
    },
    videoModal: {
      title: {
        no: '<span>Video&shy;konsultasjon</span> <span>kr 350,-</span>',
        en: 'Videoconsultation kr 350,-',
      },
      description: {
        no: 'For henvendelser som ikke krever fysisk tilstedeværelse. Last ned vår app for å bestille din videokonsultasjon.',
        en: 'For conditions that do not require a physical examination. Download our app to book your video appointment.',
      },
    },
    covidModal: {
      title: {
        no: 'Medisinsk råd over video kr 350,-',
        en: 'Medical advice over video kr 350,-',
      },
      subtitle: {
        no: 'Videokonsultasjon hvor enn du er',
        en: 'Video consultation wherever you are',
      },
      description: {
        no: 'Det er ikke alltid nødvendig å dra til legen for å få hjelpen du trenger. Ved tilstander som ikke krever fysisk undersøkelse kan vi hjelpe deg over video. Bestill time og snakk med en lege der du er - fra senga, kontoret eller hytta. Videokonsultasjon fungerer også i utlandet.\n\nLast ned appen vår Dr.Dropin for å booke video-konsultasjon:',
        en: 'You do not need to go to the GP to get the help you need. Dr.Dropin can help you with most a regular doctor or emergency room can.\n\nFor conditions that do not require physical examination or sampling, we can help you over video.\n\nDownload our app Dr.Dropin to book a video consultation:',
      },
    },
    dermaModal: {
      title: {
        no: 'Hudlege på video kr 995,-',
        en: 'Dermatologist on video kr 995,-',
      },
      subtitle: {
        no: 'Hudlege hvor enn du er',
        en: 'Dermatologist wherever you are',
      },
      description: {
        no: 'Det er ikke alltid nødvendig å dra til hudlegen for å få hjelpen du trenger. Ved tilstander som ikke krever fysisk undersøkelse kan vi hjelpe deg over video. Bestill time og snakk med en lege der du er - fra senga, kontoret eller hytta. Videokonsultasjon fungerer også i utlandet.\n\nLast ned appen vår Dr.Dropin for å booke video-konsultasjon:',
        en: 'You do not need to go to the dermatologist to get the help you need. Dr.Dropin can help you with most a regular doctor or emergency room can.\n\nFor conditions that do not require physical examination or sampling, we can help you over video.\n\nDownload our app Dr.Dropin to book a video consultation:',
      },
    },
    physioOrChiroModal: {
      title: {
        no: 'Fysioterapeut eller kiropraktor?',
        en: 'Is a physiotherapist or chiropractor right for you?',
      },
      subtitle: {
        no: 'Det er ikke mulig å velge feil. Både fysioterapeuter og kiropraktorer behandler alle smertetilstander i muskler, skjelett og ledd.',
        en: 'Both physiotherapists and chiropractors treat all pain conditions in muscles, skeleton and joints.',
      },
      description: {
        no: 'Fysioterapeuter er mest kjent for å drive behandling med hovedvekt på treningsveiledning og øvelser.\n\nHvis du tror det beste for deg er å gå til behandling der du i større grad ligger på benk, så anbefaler vi å bestille time hos kiropraktor.\n\nVerdt å vite at kiropraktor kan henvise til bildediagnostikk og sykemelde. Det kan ikke en fysioterapeut.',
        en: 'Physiotherapists are best known for conducting treatment with the main emphasis on exercises and guidance.\n\nIf you think the best thing for you is to go to treatment where you are more likely to be on a bench, then we recommend booking an appointment with a chiropractor.\n\nWorth knowing that a chiropractor can refer to imaging and sick leave. A physiotherapist can not do that.',
      },
    },
    covidFaqModal: {
      title: {
        no: 'Hvilken test bør jeg velge?',
        en: 'Which test should I choose?',
      },
      description: {
        no: '<strong>Antigen/Hurtigtest 695,-</strong>\nResultat innen 1-3 timer\n\n<ul class="list-inside"><li>Pålitelig og raskt resultat</li><li>Kan gi Fit-to-Travel attest. \n<strong>Vennligst sjekk innreisekrav for din destinasjon.</strong></li> <li>Resultatet vises på pasientsky.no eller helsenorge.no.</li> <li>Testen er produsert av ABBOTT</li></ul><hr><strong>Express RT-PCR 1895,-</strong> \nResultat innen 1-3 timer \n\n <ul class="list-inside"><li>Like rask, men mer sensitiv enn Antigen/Hurtigtest.</li><li>Kan gi Fit-to-Travel attest. <strong>Vennligst sjekk innreisekrav for din destinasjon.</strong></li><li>Analyseres i klinikken.</li><li>Resultatet vises på pasientsky.no og helsenorge.no.</li></ul> \n\n Ved test på Oslo Lufthavn, vennligst beregn god nok tid for å motta prøveresultatet. \n\n<strong>NB!: Denne testen er ikke akkreditert i henhold til NS-EN ISO 15189.</strong> <hr> <strong>Standard RT-PCR 995,-</strong> \nResultat etter 1-3 arbeidsdager \n\n<ul class="list-inside"><li>Mer sensitiv enn Antigen/Hurtigtest.</li><li>Kan gi Fit-to-Travel attest. <strong>Vennligst sjekk innreisekrav for din destinasjon.</strong></li><li>Akkreditert i henhold til NS-EN ISO 15189.</li><li>Resultatet vises på pasientsky.no og helsenorge.no.</li></ul>',
        en: '<strong>Antigen/Rapid test 695,-</strong>\nResults in 1-3 hours\n\n<ul class="list-inside"><li>Reliable and quick test</li> <li>Suitable for Fit-to-Travel GP-signed certificates. <strong>Please check the requirements of your destination country.</strong></li> <li>Results available at pasientsky.no or helsenorge.no.</li><li>Test manufactured by ABBOTT</li></ul><hr><strong>Express RT-PCR 1895,-</strong>\nResults in 1-3 hours\n\n <ul class="list-inside"><li>Fast, and more accurate than an Antigen/Rapid test.</li><li>Suitable for Fit-to-Travel GP-signed certificates. <strong>Please check the requirements of your destination country.</strong></li><li>Test is analyzed on-site.</li><li>Results available at pasientsky.no or helsenorge.no.</li></ul> \n\nIf testing at Oslo Gardermoen Airport please arrive early enough to allow for test results.        \n\n<strong>Note! This test is not accredited in accordance with NS-EN ISO 15189.</strong> <hr> <strong>Standard RT-PCR 995,-</strong> \nResults in 1-3 working days \n\n<ul class="list-inside"><li>Fast, and more accurate than an Antigen/Rapid test.</li><li>Suitable for Fit-to-Travel GP-signed certificates.  <strong>Please check the requirements of your destination country.</strong></li><li>Accredited in accordance with NS-EN ISO 15189.</li><li>Results available at pasientsky.no or helsenorge.no.</li></ul>',
      },
    },
    norwegianCovidFaqModal: {
      title: {
        no: 'Hvilken test bør jeg velge?',
        en: 'Which test should I choose?',
      },
      description: {
        no: '<strong>Antigen/Hurtigtest 595,-</strong>\nResultat innen 1-3 timer\n\n<ul class="list-inside"><li>Pålitelig og raskt resultat</li><li>Kan gi Fit-to-Travel attest. \n<strong>Vennligst sjekk innreisekrav for din destinasjon.</strong></li> <li>Resultatet vises på pasientsky.no eller helsenorge.no.</li> <li>Testen er produsert av ABBOTT</li></ul><hr> <strong>Standard RT-PCR 895,-</strong> \nResultat etter 1-3 arbeidsdager \n\n<ul class="list-inside"><li>Mer sensitiv enn Antigen/Hurtigtest.</li><li>Kan gi Fit-to-Travel attest. <strong>Vennligst sjekk innreisekrav for din destinasjon.</strong></li><li>Akkreditert i henhold til NS-EN ISO 15189.</li><li>Resultatet vises på pasientsky.no og helsenorge.no.</li></ul>',
        en: '<strong>Antigen/Rapid test 595,-</strong>\nResults in 1-3 hours\n\n<ul class="list-inside"><li>Reliable and quick test</li> <li>Suitable for Fit-to-Travel GP-signed certificates. <strong>Please check the requirements of your destination country.</strong></li> <li>Results available at pasientsky.no or helsenorge.no.</li><li>Test manufactured by ABBOTT </li></ul> <hr> <strong>Standard RT-PCR 895,-</strong> \nResults in 1-3 working days \n\n<ul class="list-inside"><li>Fast, and more accurate than an Antigen/Rapid test.</li><li>Suitable for Fit-to-Travel GP-signed certificates.  <strong>Please check the requirements of your destination country.</strong></li><li>Accredited in accordance with NS-EN ISO 15189.</li><li>Results available at pasientsky.no or helsenorge.no.</li></ul>',
      },
    },
    covidFaq: {
      title: {
        no: 'Spørsmål og svar',
        en: 'Questions and answers',
      },
      description: {
        no: 'Klinikk\n\nI klinikk har vi det nødvendige utstyret for å gjøre grundige undersøkelser, ta prøver og skrive sykemelding.\n\nVideo\n\nDet er ikke alltid nødvendig å dra til legen for å få hjelpen du trenger. Ved tilstander som ikke krever fysisk undersøkelse kan vi hjelpe deg over video. Bestill time og snakk med en lege der du er - fra senga, kontoret eller hytta. Videokonsultasjon fungerer også i utlandet.',
        en: 'You do not need to go to the clinic to get the help you need. Book an appointment and talk to a psychologist wherever you are - in bed, the office or on vacation.',
      },
    },
    errorTimeout: {
      no: 'Forespørselen tar for lang tid. Vennligst trykk her for å prøve igjen.',
      en: 'The request is taking too long, please click here to try again.',
    },
    errorPhone: {
      no: 'Vennligst tast inn et gyldig mobilnummer med (+) landskoden',
      en: 'Please enter a valid mobile number with your (+) country code',
    },
    errorWaitwhile: {
      no: 'Noe gikk galt, vennligst prøv igjen eller kontakt oss for ytterligere hjelp',
      en: 'Something went wrong, please try again or contact us for further assistance',
    },
    errorWaitwhileFull: {
      no: 'Timen er dessverre opptatt. Vennligst prøv et annet tidspunkt.',
      en: 'The appointment is already booked. Please select another appointment.',
    },
    btnErrorReset: {
      no: 'Tilbake',
      en: 'Go back',
    },
    chooseService: {
      title: {
        no: 'Velg time',
        en: 'Choose type',
      },
      regularDoctorTile: {
        title: {
          no: 'Allmennlege',
          en: 'General Physician',
        },
        description: {
          no: 'Møt allmennlege i klinikk eller på videosamtale',
          en: 'Meet a general physician in one of our many clinics or on video',
        },
        storebrandDescription: {
          no: 'Møt en allmennlege i en av våre mange klinikker',
          en: 'Meet a general physician in one of our many clinics',
        },
      },
      physicalTherapyTile: {
        title: {
          no: 'Fysikalsk',
          en: 'Physical',
        },
        description: {
          no: 'Møt fysioterapeut eller kiropraktor i klinikk eller via videosamtale',
          en: 'Meet a physiotherapist or a chiropractor in one of our clinics or by video',
        },
      },
      physicalTherapyFirstTime: {
        title: {
          no: 'Første time',
          en: 'First time',
        },
      },
      physicalTherapyFollowUp: {
        title: {
          no: 'Oppfølging',
          en: 'Follow-up',
        },
      },
      psychologistTile: {
        title: {
          no: 'Psykolog',
          en: 'Psychologist',
        },
        description: {
          no: 'Møt psykolog i klinikk på Torshov eller på videosamtale',
          en: 'Meet a psychologist in our clinic on Torshov or on video',
        },
      },
      covidTile: {
        title: {
          no: 'Covid',
          en: 'Covid',
        },
        description: {
          no: 'Koronatest, Fit-to-Fly attest, Proof of recovery eller medisinsk råd over en videosamtale',
          en: 'Covid test, Fit-to-Fly certificate, Proof of Recovery or medical advice over video.',
        },
      },
      clinic: {
        title: {
          no: 'Legetime',
          en: 'General Physician',
        },
        description: {
          no: 'Møt allmennlege ved en av våre mange klinikker',
          en: 'Meet a general physician in one of our many clinics',
        },
      },
      video: {
        title: {
          no: 'Video',
          en: 'Video',
        },
        description: {
          no: 'Få hjelp på mobilen, når og hvor du trenger det',
          en: 'Get help on your smartphone, whenever and wherever you might need it',
        },
      },
      specialist: {
        title: {
          no: 'Spesialister',
          en: 'Specialists',
        },
        description: {
          no: 'Møt hudlege, gynekolog, jordmor eller plastikkirurg på utvalgte klinikker',
          en: 'Meet a dermatologist, gynecologist, midwife or plastic surgeon in select clinics',
        },
      },
      psychology: {
        title: {
          no: 'Psykisk helse',
          en: 'Psychology',
        },
        description: {
          no: 'Få den hjelpen og støtten du trenger gjennom videosamtale med en av våre psykologer',
          en: 'Get the help and support you need through a video interview with one of our psychologists',
        },
      },
      qAndA: {
        title: {
          no: 'Spørsmål og svar',
          en: 'Q&A',
        },
        description: {
          no: 'Usikker på hvilken time du skal velge?',
          en: 'Not sure which one to choose?',
        },
        link: {
          no: 'Se flere spørsmål og svar',
          en: 'See more questions and answers',
        },
      },
    },
    bookingAddToCalendar: {
      no: 'Legg til i kalenderen',
      en: 'Add to calendar',
    },
    bookingGoogleMaps: {
      no: 'Åpne kart',
      en: 'Open in maps',
    },
    chooseArea: {
      no: 'Velg område',
      en: 'Choose area',
    },
    chooseClinic: {
      no: 'Velg område',
      en: 'Choose area',
    },
    chooseTime: {
      no: 'Velg tid',
      en: 'Choose time',
    },
    yourInfo: {
      no: 'Din informasjon',
      en: 'Your information',
    },
    bookingConfirmed: {
      no: 'Timen er bestilt',
      en: 'Appointment booked',
    },
    bookingConfirmedUrl: {
      no: 'bestilt',
      en: 'booked',
    },
    bookingConfirmBody: {
      no: 'Du får en påminnelse på SMS 1 time før timen.',
      en: 'You will receive a reminder on SMS 1 hour before the appointment.',
    },
    fullyBookedDoctorTitle: {
      no: 'Legen har ingen ledige timer denne dagen',
      en: 'The doctor has no available appointments on this day',
    },
    fullyBookedDoctorBody: {
      no: 'Du kan velge en annen lege eller bestille på en annen dato',
      en: 'You may select another doctor or book on another date',
    },
    fullyBookedPhysicalTitle: {
      no: 'Behandleren har ingen ledige timer denne dagen',
      en: 'The therapist has no available appointments on this day',
    },
    fullyBookedPhysicalBody: {
      no: 'Du kan velge en annen behandler eller bestille på en annen dato',
      en: 'You may select another therapist or book on another date',
    },
    notWorkingPhysicalTitle: {
      no: 'Behandleren jobber ikke denne dagen',
      en: 'Therapist is not working this day',
    },
    notWorkingPhysicalBody: {
      no: 'Du kan velge en annen behandler eller bestille på en annen dato',
      en: 'You may select another therapist or book on another date',
    },
    fullyBooked: {
      no: 'Klinikken/legen er fullbooket denne dagen',
      en: 'Clinic/the doctor is fully booked this day',
    },
    fullyBookedBody: {
      no: 'Du kan velge en annen klinikk/lege eller bestille på en annen dato',
      en: 'You can select another clinic/doctor or book on another date',
    },
    closed: {
      no: 'Klinikken/legen er stengt denne dagen',
      en: 'The clinic/the doctor is closed/out this day',
    },
    closedBody: {
      no: 'Du kan velge en annen klinikk eller bestille på en annen dato',
      en: 'You can select another clinic or book on another date',
    },
    otherTime: {
      no: 'Fant ikke din time',
      en: `Can't find the right time?`,
    },
    otherTimeBody: {
      no: 'Kanskje tok det for lang tid eller noe annet mystisk har skjedd? Prøv igjen, vi har mange ledige timer.',
      en: 'Maybe it took too long or something else mysteriously happened? Please try again, we have many free hours.',
    },
  },
  contact: {
    clinicsLabel: {
      no: 'Velg klinikk',
      en: 'Select clinic',
    },
    clinicsPlaceholder: {
      no: 'Velg klinikken det gjelder',
      en: 'Select clinic in question',
    },
    noClinicSelected: {
      no: 'Gjelder ikke en spesifikk klinikk',
      en: 'Does not relate to a specific clinic',
    },
    nameLabel: {
      no: 'Navn',
      en: 'Name',
    },
    namePlaceholder: {
      no: 'Fornavn Etternavn',
      en: 'Firstname Lastname',
    },
    nameErrorMessage: {
      no: 'Vennligst skriv inn navn',
      en: 'Please enter your name',
    },
    birthdateLabel: {
      no: 'Fødselsdato',
      en: 'Date of birth',
    },
    birthdatePlaceholder: {
      no: 'DD-MM-YY',
      en: 'MM/DD/YY',
    },
    birthdateErrorMessage: {
      no: 'Vennligst skriv inn gyldig fødselsdato (DD-MM-YYYY, f.eks. 04-03-2000)',
      en: 'Please enter a valid birthdate (MM/DD/YYYY, i.e. 03/04/2000)',
    },
    birthdateReasonableErrorMessage: {
      no: 'Vennligst skriv inn din faktiske fødselsdato',
      en: 'Please enter your actual birthdate',
    },
    emailLabel: {
      no: 'E-post',
      en: 'E-mail',
    },
    emailPlaceholder: {
      no: 'navn@epost.no',
      en: 'name@email.com',
    },
    phoneLabel: {
      no: 'Mobilnummer',
      en: 'Mobile number',
    },
    phonePlaceholder: {
      no: '900 00 000',
      en: '900 00 000',
    },
    phoneErrorMessage: {
      no: 'Vennligst skriv inn et gyldig mobilnummer',
      en: 'Please enter a valid mobile number',
    },
    phoneConfirmDescription: {
      no: 'For å bekrefte din bestilling må vi validere ditt mobilnummer. Du får en kode på SMS som du må skrive inn i neste steg for å fullføre bestillingen',
      en: "To confirm your booking we need to validate your mobile number. You'll receive a code via SMS to input in the next step",
    },

    descriptionErrorMessage: {
      no: 'Vennligst skriv inn problembeskrivelse',
      en: 'Please describe your issue',
    },
    descriptionLimit: {
      no: 'Maks 250 tegn',
      en: 'Max 250 characters',
    },

    confirm: {
      no: 'Bestill',
      en: 'Book',
    },

    invalidPhoneNumber: {
      no: 'Ugyldig mobilnummer. Vi støtter kun mobilnummer som kan motta SMS.',
      en: 'Invalid mobile number. We only support phone numbers that can receive text messages.',
    },
    tooManyRequests: {
      no: 'For mange forsøk. Prøv igjen senere.',
      en: 'Too many requests. Try again later.',
    },
    errorCode: {
      no: 'Feilkode: ',
      en: 'Errorcode: ',
    },
    unknownError: {
      no: 'Ukjent feil. Prøv igjen senere.',
      en: 'Unknown error. Try again later.',
    },

    codeHeading: {
      no: 'Bekreft mobilnummeret ditt for å fullføre bestillingen.',
      en: 'Confirm your mobile number to complete the booking.',
    },
    codeErrorMessage: {
      no: 'Vennligst skriv inn koden du fikk på sms.',
      en: 'Please enter the code you received via text.',
    },
    codeInvalid: {
      no: 'Feil kode.',
      en: 'Invalid code.',
    },
    codeLabel: {
      no: 'Verifiseringskode: Sendes på SMS',
      en: 'Verification code: Sent by SMS',
    },
    codePlaceholder: {
      no: '123456',
      en: '123456',
    },
    codeValid: {
      no: 'Koden er verifisert',
      en: 'The code is verified',
    },
    codeResend: {
      no: 'Ikke mottatt kode? Trykk her for å sende igjen',
      en: 'Not received a code? Click here to send again',
    },
    codeResendDetails: {
      no: 'Det kan ta inntil et minutt før du mottar koden',
      en: 'It can take up to a minute to receive the code',
    },
    messageLabel: {
      no: 'Hva gjelder det?',
      en: 'What is your inquiry?',
    },
    messagePlaceholder: {
      no: 'I stikkord',
      en: 'Your message',
    },
    messageSentConfirmation: {
      no: 'Meldingen er sendt, vi kommer tilbake til deg innen kort tid.',
      en: "Message sent, we'll get back to you shortly.",
    },
  },
  partners: {
    norwegian: {
      label: {
        no: 'Norwegian Reward nummer',
        en: 'Norwegian Reward number',
      },
      placeholder: {
        no: '00000000',
        en: '00000000',
      },
      fetch: {
        no: 'Hent ditt Reward nummer',
        en: 'Get your Reward number',
      },
      title: {
        no: 'Tjen CashPoints med Norwegian Reward',
        en: 'Earn CashPoints with Norwegian Reward',
      },
      description: {
        no: 'Som Norwegian Reward kunde får du 150 CashPoints ved første videobesøk, 150 CashPoints ved første besøk i klinikk, og 4% CashPoints på alle senere besøk.',
        en: "As a Norwegian Reward customer you'll get 150 CashPoints on the first video consultation, 150 CashPoints on the first clinic consultation, and 4% CashPoints on all appointments after.",
      },
      invalidInput: {
        no: "Du har skrevet feil Reward nummer. Reward nummeret ditt består av 8 eller 10 tall og kan bli funnet på din Norwegian Reward konto, Norwegian Reward nyhetbrev eller ved å trykke på 'Hent ditt Reward nummer' lenken nedenfor.",
        en: "You have entered an incorrect Reward Number. Your Reward number consists of 8 or 10 digits and can be found in your Norwegian Reward account, Norwegian Reward newsletters or by clicking the 'Get your Reward number' link below.",
      },
    },
    storebrand: {
      title: {
        no: 'Storebrand',
        en: 'Storebrand',
      },
      label: {
        no: 'Godkjenn',
        en: 'Accept',
      },
      description: {
        no: 'Jeg ønsker å booke timen og bekrefter at jeg godtar Dr.Dropins vilkår for bruk av tjenesten.',
        en: 'I wish to book this appointment, and confirm that I accept Dr.Dropins terms for use of the service',
      },
      invalidInput: {
        no: 'Du må godkjenne',
        en: 'You must accept',
      },
      terms: {
        no: 'Vilkår',
        en: 'Terms',
      },
      termsLink: {
        no: '/no/personvernerklaering?contentonly=1',
        en: '/en/privacy-policy?contentonly=1',
      },
    },
    bedrift: {
      sendConfirmation: {
        no: 'Send bekreftelse',
        en: 'Send confirmation',
      },
      campaignListLabel: {
        no: 'Medlemskapskampanje',
        en: 'Membership campaign',
      },
      campaignListPlaceholder: {
        no: 'Velg en medlemskapskampanje',
        en: 'Select a membership campaign',
      },
      departmentListLabel: {
        no: 'Avdeling',
        en: 'Department',
      },
      departmentListPlaceholder: {
        no: 'Velg en avdeling',
        en: 'Select a department',
      },
      hrrepLabel: {
        no: 'HR konsulent',
        en: 'HR consultant',
      },
      hrrepPlaceholder: {
        no: 'Navn',
        en: 'Name',
      },
      costcenterLabel: {
        no: 'Koststed',
        en: 'Cost center',
      },
      costcenterPlaceholder: {
        no: 'Fyll inn koststed',
        en: 'Enter cost center',
      },
      requiredInput: {
        no: 'Påkrevd',
        en: 'Required',
      },
    },
  },
  ipad: {
    heading: {
      no: 'Velkommen til Dr.Dropin',
      en: 'Welcome to Dr.Dropin',
    },
    footer: {
      title: {
        no: 'Lurer du på noe?',
        en: 'Unsure about something?',
      },
      description: {
        no: 'Finn svar på det du lurer på i vår FAQ',
        en: 'Find the answers in our FAQ',
      },
      buttonTitle: {
        no: 'Ofte stilte spørsmål',
        en: 'Frequently asked questions',
      },
    },
    showDate: {
      no: 'Velg en annen dato',
      en: 'Pick another date',
    },
  },
  practitionerView: {
    city: {
      no: 'By',
      en: 'City',
    },
    clinic: {
      no: 'Klinikk',
      en: 'Clinic',
    },
    practitionerType: {
      no: 'Behandlertype',
      en: 'Practitioner type',
    },
    services: {
      no: 'Tjenester',
      en: 'Services',
    },
    competency: {
      no: 'Kompetanse',
      en: 'Competence',
    },
    getToKnow: {
      no: 'Bli kjent med',
      en: 'Get to know',
    },
    CTA: {
      no: 'Bestill',
      en: 'Book now',
    },
  },
};
