import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import getStringTranslations from '../../gatsby-node-utils/getStringTranslations';

const MOBILE_BREAKPOINT = 976;
const TABLET_BREAKPOINT = 1100;

const propTypes = {
  pageProps: PropTypes.shape({
    pageContext: PropTypes.objectOf(PropTypes.any),
    data: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export const Context = React.createContext({
  locale: 'no',
  localizations: {},
  localizedPaths: {},
  stringTranslations: {},
  media: '',
  nearest: {},
  setNearest: () => {},
  clinicsData: [],
  isClinicsLoading: true,
  refreshClinicsData: () => {},
});

export function ContextProvider({ pageProps, children }) {
  const { pageContext } = pageProps;
  const [media, setMedia] = useState();
  const [hideMessage, setHideMessage] = useState();
  const [nearest, setNearest] = useState({ id: '', distance: '' });

  const { locale, localizedPaths } = pageContext;

  const refreshClinicsData = () => {};

  const stringTranslations = getStringTranslations(locale);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const context = {
    locale,
    stringTranslations,
    localizedPaths,
    media,
    hideMessage,
    setHideMessage,
    nearest,
    setNearest,
    clinicsData: null,
    isClinicsLoading: false,
    refreshClinicsData,
  };
  const onResize = debounce(() => {
    if (window.innerWidth > TABLET_BREAKPOINT) setMedia('desktop');
    else if (
      window.innerWidth <= TABLET_BREAKPOINT &&
      window.innerWidth > MOBILE_BREAKPOINT
    )
      setMedia('tablet');
    else setMedia('mobile');
  }, 100);

  useEffect(() => {
    if (locale) window.sessionStorage.setItem('locale', locale);
  }, [locale]);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

export function usePageContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error('usePageContext must be used within ContextProvider');
  }
  return context;
}

ContextProvider.propTypes = propTypes;
