import React from 'react';
import { navigate } from 'gatsby';
import { QueryParamProvider } from 'use-query-params';
import { ContextProvider } from './ContextProvider';

type Location = { pathname: string; search?: string; state?: any };

function generatePath(location: Location): string {
  return location.pathname + location.search;
}

const history = {
  push: (location: Location) => {
    navigate(generatePath(location), { replace: false, state: location.state });
  },
  replace: (location: Location) => {
    navigate(generatePath(location), { replace: true, state: location.state });
  },
};

export default function wrapPageElement({
  element,
  props,
}: {
  element: JSX.Element[];
  props?: any;
}): JSX.Element {
  return (
    <QueryParamProvider history={history} location={props.location}>
      <ContextProvider pageProps={props}>{element}</ContextProvider>
    </QueryParamProvider>
  );
}
